$primary-color: #333;
$secondary-color: #FF9802;
$secondary-color-hover: #ffbc00;
$light-color: #f4f4f4;
$dark_light: #F8F8F8;
$white: #FFFFFF;

$on-time: #033F73;
$on-time-light: #87a5be;

$portland-accent: #AC2020;
$portland-accent-light: #d69a9a;
$portland-primary: #3D3D3D;

$evelina: #CEEAF0;
$evelina_secondary: #CCDDE2;


$glow-yoga: #A1BA79;
$glow-yoga-light: #d9eec4;
$glow-yoga-bg: #FFFDF4;

$fin-do: #F3B459;

$snov: #7669E4;
$snov-bg: #F5F8FD;


$grey: #E5E5E5;

$home-image: url('../img/home4.jpg');
$cook-it-image: url('../img/Cook_it/main-pic.png');
$portland-image: url('../img/Portland/portland-pic.png');
$evelina-image: url('../img/Evelina/main-pic-2.png');
$glow-yoga-image: url('../img/Yoga/background-min.png');
$fin-do-image: url('../img/Fin/landing_background.png');
$snov-image: url('../img/Snov/snovio.landing.png');

$website-width: 1280px;

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@100&display=swap');